import {
  AccountType,
  AircraftType,
  AircraftVariant,
  BacklogStatus,
  BatteryType,
  BatteryTypeVariant,
  BusinessType,
  CancelReason,
  CargoCooling,
  CargoTemperatureRange,
  CargoType,
  ClarificationReason,
  DimensionUnit,
  DocumentType,
  DrawerType,
  EarliestTenderOption,
  EstimatedTransitTime,
  ExpiryReason,
  FreightStatus,
  HandlingOptions,
  LineItemType,
  MarkupCategory,
  OnboardingStep,
  PackingGroup,
  PackingInstruction,
  PerUnitType,
  QuoteStatus,
  RateCategory,
  RateImportState,
  RequestStatus,
  RouteEditorAction,
  Section,
  ServiceType,
  ShipmentStatus,
  SourceType,
  SurchargeCategory,
  SurchargeUnit,
  SurchargeWeightType,
  TimeOfDay,
  VariantType,
  WeightUnit,
} from '@pelicargo/types';

import { prettyNumber } from './pretty-number';

export const getPrettyCargoCooling = (option: CargoCooling): string => {
  switch (option) {
    case CargoCooling.GEL_PACKS:
      return 'Gel Packs';
    case CargoCooling.DRY_ICE:
      return 'Dry Ice';
    case CargoCooling.NO_COOLING:
      return 'No Cooling';
    default:
      return '';
  }
};

export const getPrettyCargoTempRange = (option: CargoTemperatureRange): string => {
  switch (option) {
    case CargoTemperatureRange.TWO_TO_EIGHT_CELSIUS:
      return '+2 to +8 °C';
    case CargoTemperatureRange.TWO_TO_TWENTY_FIVE_CELSIUS:
      return '+2 to +25 °C';
    case CargoTemperatureRange.FIFTEEN_TO_TWENTY_FIVE_CELSIUS:
      return '+15 to +25 °C';
    default:
      return '';
  }
};

export const getPrettyHandling = (option: string): string => {
  switch (option) {
    case HandlingOptions.Stackable:
      return 'Stackable';
    case HandlingOptions.TopLoadOnly:
      return 'Top Load Only';
    case HandlingOptions.NonStackable:
      return 'Non Stackable';
    case HandlingOptions.Drums:
      return 'Packed in Drums';
    case HandlingOptions.Pails:
      return 'Packed in Pails';
    case HandlingOptions.Supersacks:
      return 'Packed in Supersacks';

    // Deprecated
    case HandlingOptions.RequiredOrientation:
      return 'Required Orientation';
    case HandlingOptions.Fragile:
      return 'Fragile';
    default:
      return '';
  }
};

export const getPrettyCargoType = (option: CargoType): string => {
  switch (option) {
    case CargoType.GeneralCargo:
      return 'General Cargo';
    case CargoType.DangerousGoods:
      return 'Dangerous Goods';
    case CargoType.Perishable:
      return 'Perishable';
    case CargoType.Batteries:
      return 'Batteries';
    case CargoType.Pharma:
      return 'Pharma';
    case CargoType.Valuable:
      return 'Valuable';
    default:
      return '';
  }
};

export const getPrettySurchargeUnit = (option: SurchargeUnit): string => {
  switch (option) {
    case SurchargeUnit.AWB:
      return 'AWB';
    case SurchargeUnit.KG:
      return 'KG';
    case SurchargeUnit.UN:
      return 'UN';
    case SurchargeUnit.FLAT:
      return 'Flat';
    default:
      return '';
  }
};

export const getPrettyPerUnitType = (option: PerUnitType): string => {
  switch (option) {
    case PerUnitType.CURRENCY:
      return '$';
    case PerUnitType.PERCENTAGE:
      return '%';
    default:
      return '';
  }
};

export const getPrettySurchargeWeightType = (option: SurchargeWeightType): string => {
  switch (option) {
    case SurchargeWeightType.CHARGEABLE_WEIGHT:
      return 'Chargeable Weight';
    case SurchargeWeightType.GROSS_WEIGHT:
      return 'Gross Weight';
    default:
      return '';
  }
};

export const getPrettyServiceType = (option: ServiceType): string => {
  switch (option) {
    case ServiceType.General:
      return 'General';
    case ServiceType.Expedited:
      return 'Expedited';
    case ServiceType.Urgent:
      return 'Urgent';
    case ServiceType.Other:
      return 'Other';
    default:
      return '';
  }
};

export const getPrettySurchargeCategory = (option: SurchargeCategory): string => {
  switch (option) {
    case SurchargeCategory.FUEL:
      return 'Fuel';
    case SurchargeCategory.FWL_FHL_EDI:
      return 'FWL/FHL EDI';
    case SurchargeCategory.NON_EAWB:
      return 'Non-EAWB';
    case SurchargeCategory.SCREENING:
      return 'Screening';
    case SurchargeCategory.SECURITY:
      return 'Security';
    case SurchargeCategory.TRUCKING:
      return 'Trucking';
    case SurchargeCategory.UN_FEE:
      return 'UN Fee';
    case SurchargeCategory.LI_BATTERY:
      return 'Lithium Battery Fee';
    case SurchargeCategory.DRY_ICE:
      return 'Dry Ice Fee';
    case SurchargeCategory.NON_STACKABLE:
      return 'Non-Stackable Fee';
    case SurchargeCategory.UNKNOWN_SHIPPER:
      return 'Unknown Shipper Fee';
    default:
      return '';
  }
};

export const getPrettyLineItemType = (option: LineItemType): string => {
  switch (option) {
    case LineItemType.FUEL:
      return 'Fuel';
    case LineItemType.FWL_FHL_EDI:
      return 'FWL/FHL EDI';
    case LineItemType.NON_EAWB:
      return 'Non-EAWB';
    case LineItemType.SCREENING:
      return 'Screening';
    case LineItemType.SECURITY:
      return 'Security';
    case LineItemType.TRUCKING:
      return 'Trucking';
    case LineItemType.UN_FEE:
      return 'UN Fee';
    case LineItemType.TARIFF:
      return 'Tariff';
    case LineItemType.CONTRACT:
      return 'Contract';
    case LineItemType.PROMO:
      return 'Promo';
    case LineItemType.SPOT:
      return 'Spot';
    case LineItemType.LI_BATTERY:
      return 'Lithium Battery Fee';
    case LineItemType.DRY_ICE:
      return 'Dry Ice Fee';
    case LineItemType.NON_STACKABLE:
      return 'Non-Stackable Fee';
    case LineItemType.UNKNOWN_SHIPPER:
      return 'Unknown Shipper Fee';
    case LineItemType.FP_SERVICE:
      return 'FP Service';
    case LineItemType.OTHER_MARKUP:
      return 'Other Markup';
    case LineItemType.OTHER:
      return 'Other';
    default:
      return '';
  }
};

export const getPrettyRateCategory = (option: RateCategory): string => {
  switch (option) {
    case RateCategory.TARIFF:
      return 'Tariff';
    case RateCategory.CONTRACT:
      return 'Contract';
    case RateCategory.PROMO:
      return 'Promo';
    case RateCategory.SPOT:
      return 'Spot';
    default:
      return '';
  }
};

export const getPrettyBacklogStatus = (option: BacklogStatus): string => {
  switch (option) {
    case BacklogStatus.AtRisk:
      return 'At Risk';
    case BacklogStatus.Backlogged:
      return 'Backlogged';
    case BacklogStatus.None:
      return 'None';
    default:
      return '';
  }
};

export const getPrettyEstimatedTransitTime = (option: EstimatedTransitTime): string => {
  switch (option) {
    case EstimatedTransitTime.OneToTwoDays:
      return '1-2 Days';
    case EstimatedTransitTime.TwoToThreeDays:
      return '2-3 Days';
    case EstimatedTransitTime.ThreeToFourDays:
      return '3-4 Days';
    case EstimatedTransitTime.FourToFiveDays:
      return '4-5 Days';
    case EstimatedTransitTime.FiveToSixDays:
      return '5-6 Days';
    case EstimatedTransitTime.SixToSevenDays:
      return '6-7 Days';
    case EstimatedTransitTime.SevenDaysPlus:
      return '7+ Days';
    default:
      return '';
  }
};

export const getPrettyVariantType = (option: VariantType): string => {
  switch (option) {
    case VariantType.General:
      return 'General';
    case VariantType.Expedited:
      return 'Expedited';
    case VariantType.Urgent:
      return 'Urgent';
    case VariantType.Other:
      return 'Other';
    default:
      return '';
  }
};

export const getPrettyQuoteStatus = (option: QuoteStatus): string => {
  switch (option) {
    case QuoteStatus.Pending:
      return 'Pending';
    case QuoteStatus.SentToCarrier:
      return 'Sent to Carrier';
    case QuoteStatus.ReadyForQC:
      return 'Ready for QC';
    case QuoteStatus.InProgress:
      return 'In Progress';
    case QuoteStatus.Confirmed:
      return 'Confirmed';
    case QuoteStatus.Expired:
      return 'Expired';
    default:
      return '';
  }
};

export const getPrettyRequestStatus = (option: RequestStatus): string => {
  switch (option) {
    case RequestStatus.New:
      return 'New';
    case RequestStatus.QuotingPending:
      return 'Quoting Pending';
    case RequestStatus.QuotingInProgress:
      return 'Quoting In Progress';
    case RequestStatus.QuotingComplete:
      return 'Quoting Complete';
    case RequestStatus.BookingRequested:
      return 'Booking Requested';
    case RequestStatus.BookingPending:
      return 'Booking Pending';
    case RequestStatus.BookingComplete:
      return 'Booking Complete';
    case RequestStatus.BookingChange:
      return 'Booking Change';
    case RequestStatus.BookingCancel:
      return 'Booking Cancel';
    case RequestStatus.InfoPending:
      return 'Info Pending';
    case RequestStatus.Cancelled:
      return 'Cancelled';
    default:
      return '';
  }
};

export const getPrettyWeightUnit = (option: WeightUnit): string => {
  switch (option) {
    case WeightUnit.kg:
      return 'kg';
    case WeightUnit.lb:
      return 'lb';
    default:
      return '';
  }
};

export const getPrettyDimensionUnit = (option: DimensionUnit): string => {
  switch (option) {
    case DimensionUnit.cm:
      return 'cm';
    case DimensionUnit.in:
      return 'in';
    default:
      return '';
  }
};

export const getPrettyAircraftType = (option: AircraftType): string => {
  switch (option) {
    case AircraftType.Freighter:
      return 'Freighter';
    case AircraftType.NarrowBody:
      return 'Narrow Body';
    case AircraftType.RFS:
      return 'RFS';
    case AircraftType.WideBody:
      return 'Wide Body';
    default:
      return '';
  }
};

export const getPrettyAircraftVariant = (type: AircraftVariant): string => {
  switch (type) {
    case AircraftVariant.CAO:
      return 'CAO';
    case AircraftVariant.PAX:
      return 'PAX';
    default:
      return '';
  }
};

export const getPrettyFreightStatus = (option: FreightStatus): string => {
  switch (option) {
    case FreightStatus.Quoting:
      return 'Quoting';
    case FreightStatus.Confirmed:
      return 'Confirmed';
    default:
      return '';
  }
};

export const getPrettyExpiryReason = (option: ExpiryReason): string => {
  switch (option) {
    case ExpiryReason.INVALID_ENTRY:
      return 'Invalid Entry';
    case ExpiryReason.ROUTE_ENDED:
      return 'Route Ended';
    case ExpiryReason.SUCCEEDED:
      return 'Succeeded';
    default:
      return '';
  }
};

export const getPrettySourceType = (option: SourceType): string => {
  switch (option) {
    case SourceType.GOOGLE_SHEETS:
      return 'Google Sheet';
    default:
      return '';
  }
};

export const getPrettyBatteryType = (option: BatteryType): string => {
  switch (option) {
    case BatteryType.DG_BATTERIES_IN_EQUIPMENT:
      return 'DG Batteries';
    case BatteryType.NON_RESTRICTED_BATTERIES_IN_EQUIPMENT:
      return 'Non-Restricted Batteries';
    default:
      return '';
  }
};

export const getPrettyBatteryTypeVariant = (option: BatteryTypeVariant): string => {
  switch (option) {
    case BatteryTypeVariant.LiIon_UN3481_PI967:
      return 'Li-ion: UN3481 | PI967 | Sec II';
    case BatteryTypeVariant.LiIon_UN3481_PI966:
      return 'Li-ion: UN3481 | PI966 | Sec II';
    case BatteryTypeVariant.LiMetal_UN3091_PI969:
      return 'Li metal: UN3091 | PI969 | Sec II';
    case BatteryTypeVariant.LiMetal_UN3091_PI970:
      return 'Li metal: UN3091 | PI970 | Sec II';
    default:
      return '';
  }
};

export const getPrettyPackingInstruction = (option: PackingInstruction): string => {
  switch (option) {
    case PackingInstruction.PI965:
      return '965';
    case PackingInstruction.PI966:
      return '966';
    case PackingInstruction.PI967:
      return '967';
    case PackingInstruction.PI968:
      return '968';
    case PackingInstruction.PI969:
      return '969';
    case PackingInstruction.PI970:
      return '970';

    default:
      return '';
  }
};

export const getPrettyPackingGroup = (option: PackingGroup): string => {
  switch (option) {
    case PackingGroup.I:
      return 'I';
    case PackingGroup.II:
      return 'II';
    case PackingGroup.III:
      return 'III';

    default:
      return '';
  }
};

export const getPrettySection = (option: Section): string => {
  switch (option) {
    case Section.I:
      return 'I';
    case Section.II:
      return 'II';
    case Section.IA:
      return 'IA';
    case Section.IB:
      return 'IB';

    default:
      return '';
  }
};

export const getPrettyDocumentType = (option: DocumentType): string => {
  switch (option) {
    case DocumentType.DGD:
      return 'DGD';
    case DocumentType.MSDS:
      return 'MSDS';
    case DocumentType.AIRWAY_BILL:
      return 'Airway Bill';

    default:
      return '';
  }
};

export const getPrettyAccountType = (option: AccountType): string => {
  switch (option) {
    case AccountType.IATA:
      return 'IATA';
    case AccountType.IAC:
      return 'IAC';
    case AccountType.UNCERTIFIED:
      return 'Uncertified';
    case AccountType.ORGANIZATION:
      return 'Organization';

    default:
      return '';
  }
};

export const getPrettyBusinessType = (option: BusinessType): string => {
  switch (option) {
    case BusinessType.FREIGHT_FORWARDER:
      return 'Freight Forwarder';
    case BusinessType.GSA:
      return 'Airline / General Sales Agent (GSA)';

    default:
      return '';
  }
};

export const getPrettyShipmentStatus = (option: ShipmentStatus): string => {
  switch (option) {
    case ShipmentStatus.ACTIVE:
      return 'Active';
    case ShipmentStatus.REPLACED:
      return 'Replaced';
    case ShipmentStatus.COMPLETED:
      return 'Completed';
    default:
      return '';
  }
};

export const getPrettyRateImportState = (option: RateImportState): string => {
  switch (option) {
    case RateImportState.PENDING:
      return 'Pending';
    case RateImportState.RUNNING:
      return 'Running';
    case RateImportState.SUCCESS:
      return 'Success';
    case RateImportState.EXPIRED:
      return 'Expired';
    case RateImportState.ERROR:
      return 'Error';
    default:
      return '';
  }
};

export const getPrettyTimeOfDay = (option: TimeOfDay) => {
  switch (option) {
    case TimeOfDay.MORNING:
      return 'Morning';
    case TimeOfDay.MID_DAY:
      return 'Day';
    case TimeOfDay.EVENING:
      return 'Evening';
    default:
      return '';
  }
};

export const getPrettyEarliestTenderOption = (option: EarliestTenderOption) => {
  switch (option) {
    case EarliestTenderOption.ANYTIME:
      return 'Anytime';
    case EarliestTenderOption.TWENTY_FOUR_HOURS:
      return '24hrs';
    case EarliestTenderOption.FOURTY_EIGHT_HOURS:
      return '48hrs';
    case EarliestTenderOption.SEVENTY_TWO_HOURS:
      return '72hrs';
    default:
      return '';
  }
};

export const getPrettyOnboardingStep = (option: OnboardingStep) => {
  switch (option) {
    case OnboardingStep.CONFIRM:
      return 'Confirm';
    case OnboardingStep.PERSONAL:
      return 'Personal';
    case OnboardingStep.COMPANY_SELECT:
      return 'Company Select';
    case OnboardingStep.COMPANY:
      return 'Company';
    case OnboardingStep.SIGNATURE:
      return 'Signature';
    case OnboardingStep.INVITE:
      return 'Invite';
    case OnboardingStep.COMPLETE:
      return 'Complete';
  }
};

export const getPrettyClarificationReason = (option: ClarificationReason) => {
  switch (option) {
    case ClarificationReason.IncorrectDetails:
      return "The shipment's pieces, weights, and/or dimensions are incorrect.";
    case ClarificationReason.Oversize:
      return 'The shipment is oversized and requires more information.';
    case ClarificationReason.DangerousGoods:
      return 'The shipment involves dangerous goods and requires more information.';
    case ClarificationReason.TemperatureControl:
      return 'The shipment involves temperature control and requires more information.';
  }
};

export const getPrettyClarificationMessage = (
  option: ClarificationReason,
  numPieces,
  grossWeight,
  preferredWeightUnit,
) => {
  switch (option) {
    case ClarificationReason.IncorrectDetails:
      return `Thank you for your request. Your shipment's pieces, weights, and dimensions are flagged as potentially incorrect.
Can you please confirm this is ${numPieces} piece(s) at ${prettyNumber(grossWeight, { maximumFractionDigits: 1 })} ${preferredWeightUnit} total weight?
If so, kindly share any additional information (commodity, pictures, packing).`;
    case ClarificationReason.Oversize:
      return `Thank you for your request.  Your shipment requires special handling and additional information is required to quote.
Can you please share additional information such as the specific commodity, pictures, schematics, ready date, or packing description?`;
    case ClarificationReason.DangerousGoods:
      return `Thank you for your request.  Your shipment requires special handling and additional information is required to quote.
Can you please share the Dangerous Goods Declaration (DGD) or any additional information?
 • PAX OK or CAO
 • Class, UN, PG, PI, Section`;
    case ClarificationReason.TemperatureControl:
      return `Thank you for your request.  Your shipment requires special handling and additional information is required to quote.
Can you please clarify:
 • Specific Commodity
 • Pharma, Perishable Food or Agriculture, or Other Perishable
 • Dry Ice and Dry Ice Quantity`;
  }
};

export const getPrettyCancelReason = (option: CancelReason) => {
  switch (option) {
    case CancelReason.CargoDelay:
      return 'The cargo is delayed from the shipper.';
    case CancelReason.TransitTime:
      return 'The shipper needed a better transit time.';
    case CancelReason.Rate:
      return 'The agent requested to change carriers based on rate.';
    case CancelReason.Unknown:
      return 'The agent requested to change carriers for an unknown reason.';
  }
};

export const getPrettyRouteEditorAction = (option: RouteEditorAction) => {
  switch (option) {
    case RouteEditorAction.AirlineServiceChange:
      return 'Airline Service Change';
    case RouteEditorAction.EndRoute:
      return 'End Route';
    case RouteEditorAction.ErrorFix:
      return 'Error Fix';
    case RouteEditorAction.Invalidate:
      return 'Invalidate';
    default:
      return '';
  }
};

export const getPrettyMarkupCategory = (option: MarkupCategory) => {
  switch (option) {
    case MarkupCategory.FP_SERVICE:
      return 'FP Service';
    default:
      return '';
  }
};

export const getPrettyEmailDrawerType = (option: DrawerType) => {
  switch (option) {
    case DrawerType.AirlineBooking:
      return 'Book Quote';
    case DrawerType.AirlineQuoting:
      return 'Request Quote';
    case DrawerType.BookingCancel:
      return 'Cancel Booking';
    case DrawerType.BookingChange:
      return 'Change Booking';
    case DrawerType.BookingConfirmation:
      return 'Send Booking';
    case DrawerType.Contact:
      return 'Contact';
    case DrawerType.NewRate:
      return 'Send New Rate';
    case DrawerType.QuoteUpdate:
      return 'Send Update';
    case DrawerType.RequestClarifications:
      return 'Request Clarifications';
  }
};
