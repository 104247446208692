import { Cargo, HandlingOptions } from '@pelicargo/types';
import { uniq } from 'lodash';

import { getPrettyHandling, getPrettyPackingGroup } from '.';

const HANDLING_OPTIONS = [HandlingOptions.Stackable, HandlingOptions.TopLoadOnly, HandlingOptions.NonStackable];

const PACKING_OPTIONS = [HandlingOptions.Drums, HandlingOptions.Pails, HandlingOptions.Supersacks];

export const getHandlingAndPacking = (cargo: Cargo) => {
  const handlingAndPacking = [];
  cargo?.handling?.map((option) => {
    handlingAndPacking.push([...HANDLING_OPTIONS, ...PACKING_OPTIONS]?.find((item) => item === option));
  });
  const unique = uniq(handlingAndPacking);
  return unique?.map((node) => getPrettyHandling(node) || getPrettyPackingGroup(node)).join(', ');
};
