import {
  BoxProps,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { memo } from 'react';
import { HiChevronDown, HiMenu } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import { useAuth } from '../hooks/auth/useAuth';
import { useTargetAccount } from '../hooks/useTargetAccount';
import { AccountsModal } from './AccountsModal';
import { Logo } from './Logo';

export const Header = memo(({ ...rest }: BoxProps) => {
  const accountsModal = useDisclosure();
  const navigate = useNavigate();
  const { user, isLoggedIn, logout } = useAuth();
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const { targetAccount } = useTargetAccount();

  if (!isDesktop) {
    return (
      <>
        <Stack w="full" spacing="0" bgColor="bg.surface">
          <Stack direction="row" align="center" justify="space-between" px="4" py="2" w="full" zIndex="999">
            <Link to="/">
              <Logo />
            </Link>

            <Menu>
              <MenuButton colorScheme="gray" variant="ghost" size="lg" as={IconButton} icon={<HiMenu />}>
                {targetAccount ? targetAccount?.name : 'Select Forwarder View'}
              </MenuButton>
              <MenuList>
                <Show if={!isLoggedIn}>
                  <MenuGroup>
                    <MenuItem onClick={() => navigate('/login')}>Log in</MenuItem>
                    <MenuItem onClick={() => navigate('/register')}>Register</MenuItem>
                  </MenuGroup>
                </Show>
                <Show if={isLoggedIn}>
                  <MenuGroup>
                    <MenuItem onClick={() => navigate('/')}>New Request</MenuItem>
                    <MenuItem onClick={() => navigate('/requests')}>Request History</MenuItem>
                    <MenuItem onClick={() => navigate('/metrics')}>Metrics</MenuItem>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup title={user?.preferred_name} p="2" m="0" px="3" textStyle="label">
                    <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup noOfLines={1}>
                    <Stack pt="2" spacing="0">
                      <Text textStyle="label" px="3" mb="2" noOfLines={1} maxW="60">
                        {targetAccount ? targetAccount?.name : 'Select Forwarder View'}
                      </Text>
                      <MenuItem onClick={() => navigate('/company')}>Company Profile</MenuItem>
                      <MenuItem onClick={accountsModal.onOpen}>Switch Company</MenuItem>
                    </Stack>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuItem onClick={logout}>Log out</MenuItem>
                </Show>
              </MenuList>
            </Menu>
          </Stack>
          <Show if={isLoggedIn}>
            <Divider />
            <Stack spacing="0" px="4" py="2">
              <Heading as="h5" variant="h5" noOfLines={1}>
                {targetAccount ? targetAccount?.name : 'Select Forwarder View'}
              </Heading>
              <Text textStyle="bodySmall">{targetAccount?.iata_number ? targetAccount?.iata_number : ''}</Text>
            </Stack>
          </Show>
        </Stack>

        <Show if={accountsModal.isOpen}>
          <AccountsModal isOpen={accountsModal.isOpen} onClose={accountsModal.onClose} />
        </Show>
      </>
    );
  }

  return (
    <>
      <Flex zIndex={5} bgColor="bg.surface" py={2} borderBottomWidth={1} borderBottomColor="border.default" {...rest}>
        <Stack w="full" align="center" justify="center" px="12">
          <Stack direction="row" w="full" align="center" justify="space-between" shadow="none">
            <Stack direction="row" align="center" spacing="6">
              <Link to="/">
                <Logo />
              </Link>

              <Show if={isLoggedIn}>
                <Link to="/" tabIndex={-1}>
                  <Button variant="link" color="offBlack" size="lg">
                    New Request
                  </Button>
                </Link>
                <Link to="/requests" tabIndex={-1}>
                  <Button variant="link" color="offBlack" size="lg">
                    Request History
                  </Button>
                </Link>
                <Link to="/metrics" tabIndex={-1}>
                  <Button variant="link" color="offBlack" size="lg">
                    Metrics
                  </Button>
                </Link>
              </Show>
            </Stack>

            <Show if={!isLoggedIn}>
              <Stack direction="row" align="center" spacing="6">
                <Button variant="ghost" size="lg" onClick={() => navigate('/login')}>
                  Log in
                </Button>
                <Button colorScheme="primary" size="lg" onClick={() => navigate('/register')}>
                  Sign up
                </Button>
              </Stack>
            </Show>

            <Show if={isLoggedIn}>
              <Stack align="center" spacing="0">
                <Menu placement="top-end">
                  <MenuButton
                    colorScheme="gray"
                    variant="ghost"
                    as={Button}
                    size="sm"
                    py={2}
                    px={4}
                    h="unset"
                    rightIcon={<HiChevronDown />}
                    alignSelf="center"
                    display="flex"
                  >
                    <Text noOfLines={1} maxW={80} textStyle="captionSemibold" textAlign="right">
                      {targetAccount ? targetAccount?.name : 'Select Forwarder View'}
                    </Text>
                    <Text textStyle="captionRegular" textAlign="right">
                      {targetAccount?.iata_number}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title={user?.preferred_name} p="2" m="0" px="3" textStyle="label">
                      <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup>
                      <Stack>
                        <Text textStyle="label" px="3" noOfLines={1} maxW="60">
                          {targetAccount ? targetAccount?.name : 'Select Forwarder View'}
                        </Text>
                        <MenuItem onClick={() => navigate('/company')}>Company Profile</MenuItem>
                        <MenuItem onClick={accountsModal.onOpen}>Switch Company</MenuItem>
                      </Stack>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuItem onClick={logout}>Log out</MenuItem>
                  </MenuList>
                </Menu>
              </Stack>
            </Show>
          </Stack>
        </Stack>
      </Flex>
      <Show if={accountsModal.isOpen}>
        <AccountsModal isOpen={accountsModal.isOpen} onClose={accountsModal.onClose} />
      </Show>
    </>
  );
});
