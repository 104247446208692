import { Button, Container, Heading, Stack, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Address, BusinessType } from '@pelicargo/types';
import { useError, useSuccess } from '@pelicargo/ui';
import { businessTypeOptions, requiredZodString } from '@pelicargo/utils';
import { removeDash } from '@pelicargo/utils';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { Page } from '../../components/Page';
import { SimpleCheckbox } from '../../components/SimpleCheckbox';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { SimpleSelect } from '../../components/SimpleSelect';
import { trpc } from '../../config/trpc';
import { useTargetAccount } from '../../hooks/useTargetAccount';
import { formatIataCode } from '../../utils/inputFormatters';

type FormValues = {
  company_name: string;
  business_type: BusinessType;
  line_one: string;
  line_two: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  iata_number: string;
  is_open_to_domain_whitelist: boolean;
};

const schema = z
  .object({
    company_name: requiredZodString('Company name'),
    business_type: z.nativeEnum(BusinessType, {
      required_error: 'Business Type is required',
    }),
    line_one: requiredZodString('Street address'),
    line_two: z.string().nullish(),
    state: requiredZodString('State'),
    city: requiredZodString('City'),
    country: requiredZodString('Country'),
    zip_code: requiredZodString('Zip code'),
    iata_number: z.string().nullish(),
    is_iata_required: z.boolean().nullish(),
    is_open_to_domain_whitelist: z.boolean(),
  })
  .superRefine(({ business_type, iata_number, is_iata_required }, ctx) => {
    if (is_iata_required && business_type === BusinessType.FREIGHT_FORWARDER) {
      if (removeDash(iata_number).length < 11) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `IATA code must be 11 digits.`,
        });
      }

      if (is_iata_required && iata_number != null && iata_number !== '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `IATA code is required.`,
        });
      }
    }
  });

export const UpdateCompany = () => {
  const { handleSuccess } = useSuccess();
  const { handleError } = useError();
  const { targetAccount, changeTargetAccount } = useTargetAccount();
  const { data: billingPortalUrl, isLoading, error: billingPortalError } = trpc.getBillingPortalUrl.useQuery();

  const methods = useForm({
    resolver: zodResolver(schema),
  });

  const updateAccount = trpc.updateAccount.useMutation();

  const { data: account, error: accountError } = trpc.getAccount.useQuery(
    { id: Number(targetAccount?.id) },
    { enabled: !!targetAccount?.id },
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const domains = account?.domain_whitelist || [];
  const error = accountError || billingPortalError;

  useEffect(() => {
    if (error) {
      handleError(error?.message);
    }
  }, [error, handleError]);

  useEffect(() => {
    const address = account?.address as Address;

    methods.reset({
      company_name: account?.name,
      // TODO: prisma enum issues
      business_type: account?.business_type as any,
      line_one: address?.line_one,
      line_two: address?.line_two,
      city: address?.city,
      state: address?.state,
      country: address?.country,
      zip_code: address?.zip_code,
      iata_number: account?.iata_number,
      is_open_to_domain_whitelist: account?.is_open_to_domain_whitelist,
    });
  }, [account, methods]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        setIsSubmitting(true);

        await updateAccount.mutateAsync({
          company_name: values.company_name,
          business_type: values.business_type,
          address: {
            line_one: values.line_one,
            line_two: values.line_two,
            city: values.city,
            state: values.state,
            country: values.country,
            zip_code: values.zip_code,
          },
          iata_number: values.iata_number,
          is_open_to_domain_whitelist: values.is_open_to_domain_whitelist,
        });

        handleSuccess('Company details updated.');
        changeTargetAccount({
          id: targetAccount.id,
          name: values.company_name,
          iata_number: values.iata_number,
          is_iata_number_verified: targetAccount?.is_iata_number_verified,
        });
      } catch (error) {
        handleError(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      updateAccount,
      handleSuccess,
      changeTargetAccount,
      targetAccount.id,
      targetAccount?.is_iata_number_verified,
      handleError,
    ],
  );

  return (
    <Page title="Profile" flex="1">
      <Container maxW="container.md" px={{ base: '4', lg: '16' }} py="10">
        <Stack minH="full" w="full" spacing="6">
          <Heading variant="h3">Company</Heading>
          <SimpleForm {...methods} onSubmit={handleSubmit}>
            <Stack w="full" spacing="4">
              <Heading variant="h5">Company Profile</Heading>
              <SimpleInput name="company_name" label="Company Name *" variant="outline" />
              <SimpleSelect
                variant="outline"
                name="business_type"
                label="Business Type *"
                options={businessTypeOptions}
              />
              <SimpleInput name="line_one" label="Street Address *" variant="outline" />
              <SimpleInput name="line_two" label="Apartment/Suite/Other" variant="outline" />
              <SimpleInput name="city" label="City *" variant="outline" />
              <SimpleInput name="state" label="State *" variant="outline" />
              <Stack direction={{ base: 'column', sm: 'row' }} spacing="4" w="full">
                <SimpleInput name="country" label="Country *" variant="outline" w="full" />
                <SimpleInput name="zip_code" label="Zip Code *" variant="outline" w="full" />
              </Stack>
              <SimpleInput
                name="iata_number"
                label="IATA Code *"
                variant="outline"
                formatter={formatIataCode}
                maxLength={13}
              />
              <Stack w="full" spacing="3" mt="4">
                <Heading variant="h5">Members & Invites</Heading>
                <Text textStyle="caption">
                  To make sign-up easier, allow anyone with your domain to join. For specific invites, contact the
                  Pelicargo team.
                </Text>
                <SimpleCheckbox
                  name="is_open_to_domain_whitelist"
                  colorScheme="primary"
                  size="lg"
                  borderWidth="0"
                  background="transparent"
                  px="0"
                >
                  Let anyone with a <b>@{domains.join(', @')}</b> email join this workspace
                </SimpleCheckbox>
              </Stack>
              <Stack w="full" spacing="3" mt="4">
                <Heading variant="h5">Billing</Heading>
                <Text textStyle="caption">Manage subscriptions, payment methods, and download invoices.</Text>
                <Button as={Link} to={billingPortalUrl} rightIcon={<HiExternalLink />} isLoading={isLoading}>
                  Manage Billing
                </Button>
              </Stack>
              <Button
                colorScheme="primary"
                size="lg"
                alignSelf="flex-end"
                type="submit"
                mt="10"
                w={{ base: 'full', md: 'auto' }}
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </Stack>
          </SimpleForm>
        </Stack>
      </Container>
    </Page>
  );
};
