import { AircraftType, ServiceType } from '@pelicargo/types';
import { useCallback, useState } from 'react';

export type QuoteFilters = {
  airlines: string[];
  origins: string[];
  destinations: string[];
  stops: string;
  aircraft: 'any' | AircraftType;
  serviceType: ServiceType;
  sort: 'Cheapest' | 'Fastest';
};

export const useQuoteFilters = () => {
  const [filters, setFilters] = useState<QuoteFilters>({
    airlines: undefined,
    origins: undefined,
    destinations: undefined,
    stops: 'any',
    aircraft: 'any',
    serviceType: ServiceType.General,
    sort: 'Cheapest',
  });

  const handleCheckboxFilterChange = useCallback(
    (key: string) => (nextValue: string[]) => {
      setFilters((prev) => ({ ...prev, [key]: nextValue }));
    },
    [],
  );

  const handleToggleAll = useCallback((key: string, value: string | string[] | ServiceType) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleRadioFilterChange = (key: string) => (nextValue: string) => {
    setFilters((prev) => ({ ...prev, [key]: nextValue }));
  };

  return {
    filters,
    handleCheckboxFilterChange,
    handleRadioFilterChange,
    handleToggleAll,
  };
};
