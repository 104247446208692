import { useLocalStorage } from 'usehooks-ts';

export type TargetAccount = {
  id: number;
  name: string;
  iata_number: string | number;
  is_iata_number_verified: boolean;
};

export const useTargetAccount = () => {
  const [targetAccount, setTargetAccount] = useLocalStorage<TargetAccount>('targetAccount', null);

  const changeTargetAccount = (account: TargetAccount) => {
    setTargetAccount(account);
  };

  return {
    targetAccount,
    changeTargetAccount,
  };
};
