import {
  Button,
  Card,
  CardBody,
  Container,
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AircraftType } from '@pelicargo/types';
import { HANDLING_OPTIONS, PACKING_OPTIONS } from '@pelicargo/ui';
import {
  freightStatusOptions,
  getPrettyAircraftType,
  getPrettyCargoType,
  join,
  prettyDate,
  prettyNumber,
} from '@pelicargo/utils';
import { omit, uniq, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { HiPencil } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { z } from 'zod';

import { SimpleRequestForm } from '../../components/Forms/SimpleRequestForm';
import { Hint } from '../../components/Hint';
import { LabeledValue } from '../../components/LabeledValue';
import { Page } from '../../components/Page';
import { SimpleCheckbox } from '../../components/SimpleCheckbox';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { SimpleSwitch } from '../../components/SimpleSwitch';
import { SimpleTextarea } from '../../components/SimpleTextarea';
import { trpc } from '../../config/trpc';
import { useCreateRequest } from '../../hooks/request/useCreate';
import { RequestFormValues } from '../../hooks/request/useForm';
import { CONTAINER_2XL } from '../../utils/constants';
import { formatRequestForm } from '../../utils/formatRequestForm';
import { Calculations } from '../../utils/types';

type FormValues = {
  additional_information: string;
  customer_ref: string;
  target_rate: number;
  freight_status: string;
  is_terms_accepted: boolean;
};

const validationSchema = z.object({
  additional_information: z.string().nullish(),
  customer_ref: z.string().nullish(),
  target_rate: z.coerce
    .number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? undefined : value))
    .pipe(z.number().optional()),
  freight_status: z.enum(['Quoting', 'Confirmed']).nullish(),
  is_terms_accepted: z
    .boolean()
    .refine((val) => val, 'You must accept the Terms of Service and Privacy Policy in order to proceed.'),
});

export const RequestQuote = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [createRequest, isLoading] = useCreateRequest();
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(validationSchema),
  });
  const [documentSessionId, setDocumentSessionId] = useSessionStorage<string>('documentSessionId', '');
  const [request, setRequest] = useSessionStorage<RequestFormValues>('request', {} as any);
  const [airportIds, setAirportIds] = useState({
    from: [],
    to: [],
  });
  const [tags, setTags] = useState<string[]>([]);

  const { data: airlines } = trpc.getAirlinesForAirport.useQuery(
    { from: airportIds?.from, to: airportIds?.to },
    { enabled: !!airportIds?.from?.length && !!airportIds?.to?.length },
  );

  useEffect(() => {
    if (request) {
      const originAirportIds = request?.origin_airports;
      const destinationAirportIds = request?.destination_airports;

      setAirportIds({ from: originAirportIds, to: destinationAirportIds });

      if (request?.cargo_details === 'Gross Weight') {
        const cargoWithNullWeights = request.cargo.map((cargoItem) => ({
          ...cargoItem,
          weight: null,
        }));

        setRequest({
          ...request,
          cargo: cargoWithNullWeights,
        });
      }

      // Tags
      const handling = [];
      request?.cargo?.forEach((cargo) => {
        [cargo?.handling, ...(cargo?.packing || [])]?.map((option) => {
          handling.push([...HANDLING_OPTIONS, ...PACKING_OPTIONS]?.find((item) => item.value === option)?.label);
        });
      });

      setTags(uniq(handling));
      if (request?.is_known_shipper) {
        setTags((prevTags) => ['Known Shipper', ...prevTags]);
      }
    }
  }, [request, setRequest]);

  const calculations: Calculations = useMemo(() => {
    const vw = request?.volumetric_weight;
    const gw = request?.gross_weight;

    const cw = Math.max(vw, gw);

    return { cw, vw, gw };
  }, [request?.volumetric_weight, request?.gross_weight]);

  const handleModalSubmit = (values: RequestFormValues) => {
    const formattedRequestForm = formatRequestForm(values);

    setRequest(formattedRequestForm);
    onClose();
  };

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const formattedRequest = formatRequestForm(request, {
          isFinalFormat: true,
        });
        const data = {
          ...formattedRequest,
          ...values,
          documentSessionId,
        };

        const cleaned = omit(data, [
          'total_pieces',
          'service_type',
          'flight',
          'is_terms_accepted',
          'battery_type_variant',
          'dgdTab',
          'document',
        ]);
        const nextCleaned = {
          ...cleaned,
          cargo: cleaned.cargo.map((cargo) => ({
            ...omit(cargo, 'packing'),
            handling: [cargo?.handling, ...(cargo?.packing || [])],
          })),
        };

        const nextRequest = await createRequest(nextCleaned);

        if (nextRequest?.id) {
          setRequest({} as any);
          setDocumentSessionId('');
          navigate(`/requests/${nextRequest.id}`, {
            state: {
              from: '/request-quote',
              request: nextRequest,
            },
          });
        }
      } catch (error) {
        toast({
          title: 'Something went wrong',
          description: error?.message,
          status: 'error',
        });
      }
    },
    [request, documentSessionId, createRequest, setRequest, setDocumentSessionId, navigate, toast],
  );

  const renderCargo = useCallback(
    (cargo: RequestFormValues['cargo'][0], index: number) => {
      return (
        <SimpleGrid key={String(index)} columns={{ base: 2, '2xl': 5 }} gap={{ base: 4, lg: 2 }}>
          <LabeledValue label="Quantity" value={`${cargo?.quantity}x` || '-'} />
          <LabeledValue label={`Length (${request?.original_dimension_unit})`} value={cargo?.length || '-'} />
          <LabeledValue label={`Width (${request?.original_dimension_unit})`} value={cargo?.width || '-'} />
          <LabeledValue label={`Height (${request?.original_dimension_unit})`} value={cargo?.height || '-'} />
          <LabeledValue label={`Weight (${request?.original_weight_unit})`} value={cargo?.weight || '-'} />
        </SimpleGrid>
      );
    },
    [request?.original_dimension_unit, request?.original_weight_unit],
  );

  const renderAirline = (airline) => {
    return (
      <WrapItem key={airline?.id}>
        <Tag color="offBlack" fontWeight="semibold" variant="outline" rounded="full" size="md">
          {airline?.name}
        </Tag>
      </WrapItem>
    );
  };

  const renderCheckedTag = (value: string) => {
    return (
      <WrapItem key={value}>
        <Tag size="md">{value}</Tag>
      </WrapItem>
    );
  };

  const cargoType = getPrettyCargoType(request?.cargo_type);

  return (
    <Page title="Request Quote" flex="1">
      <Container w="full" maxW={CONTAINER_2XL} p="0">
        <Stack px={{ base: '2.5', sm: '4', md: '5', lg: '6' }} py={{ base: '2.5', lg: '10' }} spacing="6">
          <Stack spacing="0" w="full">
            <Text textStyle="label">ROUTE</Text>
            <Heading variant="h3">
              {join(request?.origin_airports)} → {join(request?.destination_airports)}
            </Heading>
          </Stack>
          <Stack direction={{ base: 'column', lg: 'row' }} w="full" spacing="6">
            <Stack w="full" spacing="8">
              <Hint name="requestQuote-requestSummary">
                <Card h="inherit" px="4" py="3">
                  <CardBody p="0">
                    <Stack spacing="8">
                      <HStack justify="space-between">
                        <Heading size="md">Cargo Info</Heading>
                        <Button onClick={onOpen} leftIcon={<HiPencil />}>
                          Edit
                        </Button>
                      </HStack>
                      <SimpleGrid columns={{ base: 2, '2xl': 3 }} gap={4}>
                        <LabeledValue label="Expected Drop-Off" value={prettyDate(request?.dropoff_by)} />
                        <LabeledValue label="Commodity" value={request?.commodity || '-'} />
                      </SimpleGrid>
                      <SimpleGrid columns={{ base: 2, '2xl': 3 }} gap={4}>
                        <LabeledValue label="Cargo Type" value={cargoType ?? '-'} />
                        <LabeledValue label="Cargo Details" value={request?.cargo_details ?? '-'} />

                        <LabeledValue label="Units" justify="space-between">
                          <HStack w="fit-content">
                            {renderCheckedTag(request?.original_weight_unit)}
                            {renderCheckedTag(request?.original_dimension_unit)}
                          </HStack>
                        </LabeledValue>
                      </SimpleGrid>

                      <SimpleGrid columns={{ base: 2, '2xl': 3 }} gap={4}>
                        <LabeledValue label="Gross Weight" value={prettyNumber(calculations?.gw)} />
                        <LabeledValue label="Volumetric Weight" value={prettyNumber(calculations?.vw)} />
                        <LabeledValue label="Chargeable Weight" value={prettyNumber(calculations?.cw)} />
                      </SimpleGrid>
                      <Wrap>{tags?.map(renderCheckedTag)}</Wrap>

                      {request?.cargo?.map(renderCargo)}
                    </Stack>
                  </CardBody>
                </Card>
              </Hint>

              <Card h="inherit" px="4" py="3">
                <CardBody p="0">
                  <Stack spacing="8">
                    <Heading size="md">Service Request</Heading>
                    <HStack align="baseline" spacing="8">
                      <LabeledValue label="Flight" value={request?.flight} />
                      <LabeledValue label="Product" value={request?.service_type} />
                    </HStack>
                    <Wrap>
                      {Object.values(AircraftType)?.map((value) => renderCheckedTag(getPrettyAircraftType(value)))}
                    </Wrap>
                    <Wrap>{uniqBy(airlines, 'name')?.map(renderAirline)}</Wrap>
                  </Stack>
                </CardBody>
              </Card>
            </Stack>
            <Stack w="full">
              <Card h="initial" px="4" py="3">
                <CardBody p="0">
                  <SimpleForm onSubmit={handleSubmit} defaultValues={request} {...(methods as UseFormReturn<any, any>)}>
                    <Stack spacing={{ base: '6', lg: '10' }} h="full">
                      <Stack spacing="6">
                        <Stack spacing="0">
                          <Heading variant="h4">Additional Information (optional)</Heading>
                          <Text textStyle="captionRegular">
                            Please let us know any other relevant information such as cargo description, airline
                            preferences, gateway considerations, etc
                          </Text>
                        </Stack>

                        <SimpleTextarea
                          name="additional_information"
                          variant="outline"
                          placeholder="Tell us more about your quote..."
                          rows={8}
                        />
                      </Stack>
                      <Stack spacing="6">
                        <Stack spacing="0">
                          <Heading variant="h4">Your Reference Number</Heading>
                          <Text textStyle="captionRegular">
                            Please enter the tracking number from your TMS to have it included in all communications.
                          </Text>
                        </Stack>
                        <SimpleInput
                          name="customer_ref"
                          variant="outline"
                          placeholder="Please enter the tracking number from your TMS"
                        />
                      </Stack>
                      <Stack spacing="6" w="full">
                        <Stack spacing="0">
                          <Heading variant="h4">Spot Quotes</Heading>
                          <Text textStyle="captionRegular">
                            Do you have the freight or know what is needed to win it?
                          </Text>
                        </Stack>
                        <Stack
                          direction={{ base: 'column', lg: 'row' }}
                          align={{ base: 'auto', lg: 'center' }}
                          w="full"
                          spacing="6"
                        >
                          <SimpleSwitch
                            name="freight_status"
                            label="Freight"
                            options={freightStatusOptions}
                            w="full"
                            variant="outline"
                          />
                          <SimpleInput
                            name="target_rate"
                            label="Target Rate"
                            placeholder="Target Rate"
                            autoComplete="off"
                            w="full"
                            type="number"
                            variant="outline"
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack flex="1" direction="column" justify="flex-end" spacing="4" pt="20">
                      <SimpleCheckbox
                        variant="unstyled"
                        colorScheme="primary"
                        name="is_terms_accepted"
                        size="lg"
                        label="By checking, I accept the Terms of Service and Privacy Policy"
                      />

                      <Button type="submit" colorScheme="primary" size="lg" borderRadius="md" isLoading={isLoading}>
                        Request Quotes
                      </Button>
                    </Stack>
                  </SimpleForm>
                </CardBody>
              </Card>
            </Stack>
          </Stack>
        </Stack>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" size={{ base: 'full', lg: '5xl' }}>
        <ModalOverlay />
        <ModalContent p={{ base: '4', lg: '8' }}>
          <ModalHeader px="0">
            <Heading>Choose the best priced flights</Heading>
            <ModalCloseButton />
          </ModalHeader>
          <SimpleRequestForm submitLabel="Update" isUpdate onSubmit={handleModalSubmit} />
        </ModalContent>
      </Modal>
    </Page>
  );
};
